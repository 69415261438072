import styled from 'styled-components';
import { ContainerColumnCenter } from '../../styles/Common';
import { deviceMax } from '../../styles/MediaQuery';
import { black, blue, midGreyBorder } from '../../styles/Variables';

export const Container = styled(ContainerColumnCenter)`
    width: 620px;
    margin: 0 auto;
    margin-top: 3.5rem;

    @media ${deviceMax.tablet} {
        width: calc(100% - 30px);
        padding: 0 15px;
        word-break: break-word;
    }
`;

export const EventWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
`;

export const EventContent = styled.div`
    width: 100%;

    * {
        color: ${black};
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    img {
        max-width: 620px;
        width: 100%;
        height: auto;

        @media ${deviceMax.tablet} {
            width: unset;
            height: auto !important;
            max-width: 100%;
            object-fit: cover;
        }
    }
`;

export const Title = styled.h1`
    color: ${blue};
    font-size: 2.5rem;
    letter-spacing: 0.025em;
    margin: 0;
    margin-bottom: 1.1rem;
    line-height: 2.75rem;
    font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, sans-serif;
    word-break: break-word;
`;

export const SummaryWrapper = styled.div`
    width: 100%;
    margin: 0 0 20px 0;
`;

export const SummaryDate = styled.p`
    width: 100%;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.45;
    font-family: Arial, Helvetica, sans-serif;
    border-bottom: 1px solid ${midGreyBorder};
    margin: 0;
    text-transform: capitalize;
`;

export const SummaryText = styled.div`
    width: 100%;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.45;
    font-family: Arial, Helvetica, sans-serif;
`;

export const EventImage = styled.img`
    width: 100%;
    height: 320px;
    object-fit: fill;

    @media ${deviceMax.tablet} {
        height: auto;
    }
`;
